<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tabla Dinamica Usuarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Empresas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-header pt-2 pb-2">
            <div class="row">
              <div class="col-md-6">
                <h5>Filtros</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Acordeón de filtros -->
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Seleccionar Tipo Busqueda</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="tipo_consulta"
                    :class="
                      $v.tipo_consulta.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option :value="1">Usuarios</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-2">
                <label>Nombre</label>
                <input
                  type="text"
                  id="nombre"
                  placeholder="Nombre"
                  label="Nombre"
                  v-model="filtros.nombre"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Email</label>
                <input
                  type="text"
                  id="email"
                  placeholder="Email"
                  label="Email"
                  v-model="filtros.email"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Tipo de Documento</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.type_document"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_documento in listasForms.tipos_documento"
                    :key="tipo_documento.numeracion"
                    :value="tipo_documento.numeracion"
                  >
                    {{ tipo_documento.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Numero de documento</label>
                <input
                  type="number"
                  id="n_documento"
                  placeholder="Numero de Documento"
                  label="n_documento"
                  v-model="filtros.n_documento"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Celular</label>
                <input
                  type="number"
                  id="cel"
                  placeholder="Celular"
                  label="cel"
                  v-model="filtros.cel"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Estado</label>
                <select
                  id="estado1"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.status"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  v-show="!$v.tipo_consulta.$invalid"
                  class="btn bg-navy btn-sm"
                  @click="generarPivotTable()"
                  v-if="$store.getters.can('admin.usuarios.pivotTable')"
                >
                  Generar Busqueda
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <pivot-table-component
                      v-if="pivotData"
                      :pivotData="pivotData"
                      :aggregator-name="aggregatorName"
                      :renderer-name="rendererName"
                    >
                    </pivot-table-component>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "SolicitudPivotTable",
  components: {
    PivotTableComponent,
    Loading,
  },
  data() {
    return {
      cargando: false,
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      filtros: {
        nombre: null,
        email: null,
        type_document: null,
        n_documento: null,
        cel: null,
        status: null,
      },
      tipo_consulta: null,
      listasForms: {
        tipos_documento: [],
        estados: [],
      },
    };
  },
  validations: {
    tipo_consulta: {
      required,
    },
  },
  methods: {
    back() {
      return this.$router.replace("/Admin/Usuarios");
    },
    async getTiposIdentificacion() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipos_documento = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    generarPivotTable() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/usuarios/pivotTable",
        data: { tipo_consulta: this.tipo_consulta, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          this.pivotData = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getTiposIdentificacion();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
